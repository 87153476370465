
body {
  @apply overflow-y-hidden;
}

.text_color_primary {
  color: #1353B7 !important;
}

.text_color_accent {
  color: #D92222 !important;
}

.bg_color_primary {
  background-color: #1353B7 !important;
}

.bg_color_accent {
  background-color: #D92222 !important;
}

.border_color_primary {
  border-color: #1353B7 !important;
}

.border_color_accent {
  border-color: #D92222 !important;
}

.svg_color_primary {
  fill: #1353B7 !important;
  stroke: #1353B7 !important;
}

.svg_color_accent {
  fill: #D92222 !important;
  stroke: #D92222 !important;
}

.selected_common {
  background-color: #1353B7;
  border-color: #1353B7 !important;
  color: white;
}

.target-selected-color {
  background-color: rgb(153, 173, 196);
  border-color: rgb(153, 173, 196) !important;
  color: white;
}

.hover_common:hover {
  opacity: 0.5;
}

.page_content_area {
  @apply flex-1 overflow-y-hidden pt-1 bg-[#F5F5F5];
}

.page_main_area {
  @apply w-full overflow-y-scroll mb-2 px-4;
  overflow-y:auto;
  height: calc(100% - 8rem);
}

/**
 * 設定ナビゲーションページ
 */
.page_main_area_navigate_page {
  @apply w-full overflow-y-scroll mb-2 px-4;
  overflow-y:auto;
  height: calc(100% - 3rem);
}

/* 調整済み */
.page_main_area_list_page {
  @apply w-full overflow-y-scroll mb-2 px-4;
  overflow-y:auto;
  height: calc(100% - 3rem);
}

.page_main_area_list_page_new {
  @apply w-full h-full overflow-y-auto mb-2 px-4;
  overflow-y:auto;
  /* height: calc(100% - 3rem); */
}

.page_main_area_list_with_primary_button {
  @apply w-full overflow-y-scroll mb-2 px-4;
  overflow-y:auto;
  height: calc(100% - 6rem);
}

.page_main_area_with_primary_button {
  @apply w-full overflow-y-scroll mb-2 px-4;
  overflow-y:auto;
  height: calc(100% - 100px);
}

.page_main_area_no_button {
  @apply w-full overflow-y-scroll mb-2 px-2;
  overflow-y:scroll;
  height: 100%
}

.page_button_area {
  @apply bg-gray-300 flex overflow-y-hidden items-center ;
  width: 100%;
  height: 5rem;

  position: fixed;
  bottom: 0;
}

.page_button_subarea {
  @apply flex justify-end items-center;
  width: 100%;
}

/* -------------------------------------------------------
/* ヘッダ
/* ------------------------------------------------------- */

.header {
  @apply navbar bg-bgHeader flex justify-between z-10 shadow-md fixed top-0 left-0 right-0 h-11 min-h-0 px-2 lg:px-4 gap-1;
  background-color: #1353B7;
}

.header_company_select {
  @apply flex gap-[2px] lg:gap-[8px] items-center border-2 bg-white overflow-hidden px-1 lg:px-4 hover:text-opacity-70 hover:opacity-70;
}


/* -------------------------------------------------------
/* ディバイダ
/* ------------------------------------------------------- */

.divider::before {
  background-color: #1353B7;
  height: 0.1rem;
}

.divider::after {
  background-color: #1353B7;
  height: 0.1rem;
}

.divider.divider-thin::before ,
.divider.divider-thin::after
{
  height: 1px;
}

/* -------------------------------------------------------
/* サイドバー
/* ------------------------------------------------------- */

.left_sidebar_area {
  /*NOTE サイドバー対応によりコメントアウト*/
  /* @apply drawer-side md:fixed; */
  position: fixed;
  background-color: #E3E8F0;

  height: 100dvh;
  overflow-y: clip;
  color: #1353B7;
}

.left_sidebar_area * {
  color: #1353B7;
  stroke: #1353B7;
}

.left_sidebar_area .divider::before {
  background-color: #1353B7;
}

.left_sidebar_area .divider::after {
  background-color: #1353B7;
}

.left_sidebar_toggle_btn {
  @apply flex mt-[5px] mr-[5px] w-[40px] h-[40px] rounded-lg;
  background-color: white;
}

.left_sidebar_toggle_btn * {
  @apply w-[20px] h-[20px] m-auto;
  stroke: black;
}

.selected_menu {
  @apply absolute inset-y-0 left-[2px] w-[4px];
  background-color: #1353B7;
  border-radius: 2px;
}

.left_sidebar_area a {
  padding-right: 6px;
}

.left_sidebar_area a {
  background-color: transparent;
}


/* ---------------------------------------------------
/* プライマリボタン
/* ------------------------------------------------------- */

.page_button_area  .register_button {
  @apply btn h-[55px] w-[150px] mr-[20px];
  background-color: #1353B7;
  color: white;
  border-color: #1353B7;
}

.page_button_area  .register_button:hover {
  @apply opacity-50;
  background-color: #1353B7;
  color: white;
  border-color: #1353B7;
}

.page_button_area  .cancel_button {
  @apply btn h-[55px] mr-5 w-[150px];
  background-color: #EEEEEE;
  color: #000000;
  border-color: #1353B7;
  border-width: 1px;
  border-style: solid;
}

.page_button_area  .cancel_button:hover {
  @apply opacity-50;
  background-color: #EEEEEE;
  color: #000000;
  border-color: #1353B7;
  border-width: 1px;
  border-style: solid;
}

/* -------------------------------------------------------
/* アイコン
/* ------------------------------------------------------- */

@media (max-width: 768px) {
  .timeline-container {
    grid-template-columns: repeat(6, 1fr); /* 列数を6に調整 */
  }
  .timeline-item {
    width: 90%; /* 幅を縮小 */
    margin: 0 auto; /* 中央寄せ */
  }
}

/* -------------------------------------------------------
/* アイコン
/* ------------------------------------------------------- */

.image_icon {
  stroke: white;
}

.bg-custom-blue {
  @apply btn btn-sm border-none h-[44px] px-5 font-bold text-white rounded-md;
  background-color: #1353B7;
}

/* -------------------------------------------------------
/* パンくずリスト
/* ------------------------------------------------------- */

.bread_crumbs_ancestor {
  color: #048cc7;
}

/* -------------------------------------------------------
/* カード
/* ------------------------------------------------------- */

/* ページカード */
.page_card {
  @apply card w-full p-4 lg:p-6 bg-base-100 shadow-xl mb-[30px];
}

.page_card_outer {
  min-height: calc(100dvh - 100px);
  background-color: white;
}

.page_card_outer_list_with_primary_button {
  min-height: calc(100dvh - 180px);
  background-color: white;
}

/* 一般用カード */
.general_card {
  @apply card bg-base-100 shadow-xl;
  border-radius: 0.5rem;
}

.card_hover:hover {
  color: #FFFFFF;
  background-color: #1353B7;
}

/* -------------------------------------------------------
/* 設定ページリンク
/* ------------------------------------------------------- */

.setting_page_link {
  @apply link mb-2;
}

/* -------------------------------------------------------
/* テーブルコンポーネント
/* ------------------------------------------------------- */

.data_table th {
  @apply text-[14px];
  border-radius:0px;
  border-right-style: solid;
  border-right-color: white;
  border-right-width: 5px;
  font-weight: 500;
}

.data_table td {
  border-radius:0px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
}

.data_table th:last-child {
  border-right-width: 0px;
}

.data_table td svg {
  stroke: #1353B7;
  fill: #1353B7;
}

.data_table_selected {
  background-color: rgba(0, 49, 108, 0.4) !important;
}


.data_table_hover:hover {
  background-color: #F2FDFE;
  stroke: white;
  fill: white;
}



/*
.data_table_hover:hover svg {
  stroke: white;
  fill: white;
}

.data_table_hover:hover input {
  border-color: white;
}
*/

/* .grid-row-icon-button { */
.data_table_icon_button {
  @apply text-2xl w-[30px] h-[30px] cursor-pointer m-auto;
}

.data_table_icon_button .grid-row-icon-button-icon {
  @apply m-auto;
}

.data_table_icon {
  @apply text-2xl w-[30px] h-[30px] m-auto;
}

/*.grid-row-icon-button-disabled { */
.data_table_icon_button_disabled {
  @apply text-2xl w-[30px] h-[30px] cursor-not-allowed opacity-50 m-auto;
}

.data_table .center_row {
  @apply m-auto;
}

.data_table .alert_row {
  @apply bg-red-100;
}

.data_table .resizable {
  @apply resize-x overflow-hidden;
}

.data_table .sortable {
  @apply flex justify-between mr-3;
}

.data_table .emphasis {
  @apply font-bold;
}

.data_table .icon_header {
  @apply w-[60px] m-auto;
}

.sotable_header {
  cursor: pointer;

}

.sotable_header .sortable_icon_div
{
  height: 10px;
  width: 20px;
  float: right;
}

.sotable_header .sortable_icon_div::before
{
  content: "";
  height: 12px;
  width: 12px;
  position: absolute;
  border: 6px solid transparent;;
  border-bottom-color: #aaa;
  margin-top: -8px;
}
.sotable_header.sort_asc .sortable_icon_div::before
{
  border-bottom-color: #555;
}

.sotable_header .sortable_icon_div::after {
  content: "";
  height: 12px;
  width: 12px;
  position: absolute;
  border: 6px solid transparent;;
  border-top-color: #aaa;
  margin-top: 8px;
}
.sotable_header.sort_desc .sortable_icon_div::after {
  border-top-color: #555;
}

.table_number {
  line-height: 1.5rem;
  font-size: 1rem;
}

.table_option_info {
  margin-bottom: 5px;
  font-size: 16px;
}

.table_option_info * {
  font-size: 16px;
}

/* -------------------------------------------------------
/* トップサイドボタン
/* ------------------------------------------------------- */

.top_side_button_area {
  @apply flex text-right items-center justify-between;
}

/* -------------------------------------------------------
/* プライマリラベル
/* ------------------------------------------------------- */

.primary_label {
  @apply flex;
  height: 44px;
}

.primary_label div {
  @apply w-[4px];
  background-color: #1353B7;
}

.primary_label span {
  @apply text-[21px] font-bold ml-[5px] self-center;
}




/* -------------------------------------------------------
/* ページネーション
/* ------------------------------------------------------- */

.pagination_area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

.pagination_area a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 40px;
  width: 40px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination_area a.active {
  background-color: #1353B7;
  color: white;
  border: 1px solid #1353B7;
  border-radius: 4px;
}

.pagination_area a.disabled {
  border: none;
  cursor: default;
}

/* -------------------------------------------------------
/* タブ
/* ------------------------------------------------------- */

.tab_area {
  @apply flex;
  gap: 5px;
}

.tab_cell {
  min-width: 150px;
  max-width: 500px;
  text-overflow: ellipsis;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
}

.selected_tab {
  background-color: #1353B7;
  border-color: #1353B7 !important;
  color: white;
}

/* -------------------------------------------------------
/* アイコンコンポーネント
/* ------------------------------------------------------- */

.icon_button {
  @apply text-3xl w-[30px] h-[30px] cursor-pointer;
}

.icon_button svg {
  stroke: #1353B7;
  fill: #1353B7;
}

.icon_button_disabled {
  @apply text-3xl w-[30px] h-[30px] cursor-not-allowed opacity-50;
}

.icon_button:hover {
  @apply opacity-50;
}

.icon_button.icon_button_sm {
  @apply w-[24px] h-[24px];
}

/* -------------------------------------------------------
/* ボタンコンポーネント
/* ------------------------------------------------------- */
.operate_button {
  @apply btn btn-sm border-none h-[44px] px-5 font-bold text-white rounded-md;
  background-color: #1353B7;
}

.operate_button:hover {
  opacity: 0.5;
  background-color: #1353B7;
}

.operate_outline_button {
  @apply btn btn-sm bg-white border-2 border-gray-300 h-[44px] rounded-md text-black font-bold;
}

.operate_outline_button:hover {
  opacity: 0.8;
  background-color:transparent
}

.reference_button {
  @apply btn border-none h-[44px] px-5 font-bold text-white mb-[8px];
  background-color: #048CC7;
}

.reference_button:hover {
  opacity: 0.5;
  background-color: #048CC7;
}

.button_disabled {
  @apply !cursor-not-allowed;
  opacity: 0.5;
}

/* -------------------------------------------------------
/* 外部リンクコンポーネント
/* ------------------------------------------------------- */

.link_internal {
  @apply link text-base underline cursor-pointer;
  color: #048CC7;
}

.link_internal:hover {
  opacity: 0.5;
  color: #1353B7;
}

.link_external {
  @apply link text-base underline cursor-pointer;
  color: #048CC7;
}

.link_external:hover {
  opacity: 0.5;
  color: #1353B7;
}

/* -------------------------------------------------------
/* 入力コンポーネント
/* ------------------------------------------------------- */
.input_text {
  @apply input border-2 shadow-inner	input-bordered w-full text-black font-medium pr-8;
}

.input_file {
  @apply input border-2 shadow-inner	input-bordered w-full text-black font-medium pr-8 pl-0;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.text_area {
  @apply textarea border-2 shadow-inner textarea-bordered text-base w-full text-black font-medium pr-8;
}

.select_list {
  @apply input border-2 shadow-inner	input-bordered w-full text-black font-medium pr-8;
}

@media (max-width: 640px) {
  .select_list {
    font-size: 14px;
    padding: 6px;
    height: 100px;
    overflow-y: auto; /* スクロールバーを表示 */
  }
}

.readonly {
  @apply bg-gray-200 duration-75;
}

input {
  @apply !border-2;
}

select {
  @apply !border-2;
}

input:disabled  {
  @apply bg-gray-200 !border-2 !border-[rgb(209,213,219)];
}

select:disabled {
  @apply !bg-gray-200 !border-2 !border-[rgb(209,213,219)];
}

.required {
  @apply text-red-500 font-semibold text-[12px] grid-cols-3 ml-2 p-[1px];
  border-width: 1px;
  border-radius: 0.3rem;
  border-color: #D92222;
}

.ref_button {
  @apply btn ml-1 border-0 w-[90px];
  background-color: #048cc7;
}

.bukken_input input {
  margin-left:15px;
}

.bukken_input select {
  margin-left:15px;
}

.bukken_input .bukken_room {
  margin-left:15px;
}

.bukken_input .bukken_multi_input {
  padding-left: 0px !important;
}

/* -------------------------------------------------------
/* ファイル登録コンポーネント
/* ------------------------------------------------------- */

input::file-selector-button {
  border-color: #048cc7 !important;
  color: #ffffff !important;
  background-color: #048cc7 !important;
  padding-bottom: 6px;
}

/* -------------------------------------------------------
/* 日付ピッカーコンポーネント
/* ------------------------------------------------------- */

.react-datepicker__close-icon::after {
  background-color: #1353B7 !important;
}

/* -------------------------------------------------------
/* プラス/マイナスコンポーネント
/* ------------------------------------------------------- */

.plus_or_minus_plus {
  color: #1353B7;
}

.plus_or_minus_plus:hover {
  background-color: #1353B7;
  color: white;
}

/* -------------------------------------------------------
/* ページ検索バー
/* ------------------------------------------------------- */

.page_search_bar {
  @apply h-[44px] placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-[#1353B7] focus:ring-sky-500 focus:ring-1 sm:text-sm;
}

/* -------------------------------------------------------
/* ダイアログ
/* ------------------------------------------------------- */

.dialog_container {
  border-radius: 10px;
  overflow-y: auto;
}

.dialog_button_area  .register_button {
  @apply btn btn-md w-[150px];
  background-color: #1353B7;
  border-color: #1353B7;
}

.dialog_button_area  .register_button:hover {
  @apply opacity-50;
  background-color: #1353B7;
  border-color: #1353B7;
}

.dialog_button_area  .cancel_button {
  @apply btn btn-md w-[150px];
  background-color: #EEEEEE;
  color: #000000;
  border-color: #1353B7;
}

.dialog_button_area  .cancel_button:hover {
  @apply opacity-50;
  background-color: #EEEEEE;
  color: #000000;
  border-color: #1353B7;
}

/* -------------------------------------------------------
/* ローディング
/* ------------------------------------------------------- */

.loading-indicator:before {
  content: "";
  background: #00000080;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: " ";
  position: fixed;
  top: 40%;
  left: 45%;
  z-index: 10010;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #1353B7; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

/* 携帯画面用のスタイル */
@media (max-width: 768px) {
  .loading-indicator:after {
    top: 40%;
    left: 35%; /* 左に寄せる */
  }
}

.loading-indicator-small:after {
  content: " ";
  position: absolute;
  top: 5%;
  right: 4%;
  z-index: 10010;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #1353B7; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1.5s linear infinite;
}

/* 携帯画面用のスタイル */
@media (max-width: 768px) {
  .loading-indicator-small:after {
    top: 40%;
    left: 35%; /* 左に寄せる */
  }
}

/* -------------------------------------------------------
/* ヘッダ
/* ------------------------------------------------------- */

.header_company_select {
  background-color: #1353B7;
  padding: 0px;
  border-width: 0px;
  color: white;
}

.header_company_select * {
  background-color: transparent;
}

.header_company_select select {
  border-style: solid;
  border-bottom-width: 1px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-top-width: 0px !important;
}

.header_company_select option {
  color: black;
}

/* -------------------------------------------------------
/* フォーム
/* ------------------------------------------------------- */

.form_control {
  @apply form-control mb-2 mt-2;
}

.form_control .label {
  @apply pb-[2px] pt-[0px];
  font-size: 18px;
}

.form_control .label span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.form_label_control .primary_label span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.form_control input[type="radio"] {
  height: 24px;
}

.form_control input[type="checkbox"] {
  height: 24px;
}

.form_control input {
  height: 44px;
}


.form_control select {
  height: 44px;
}

.form_label {
  @apply text-black text-[18px] font-semibold;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* -------------------------------------------------------
/* ツリービュー
/* ------------------------------------------------------- */

.tree_area:hover {
  background-color:transparent;
  cursor: pointer;
}

.tree_area *:hover {
  background-color:transparent;
  cursor: pointer;
}


.tree_selected {
  background-color: rgba(0, 49, 108, 0.4) !important;
  padding: 1px;
  border-color: rgba(0, 49, 108, 0.4) !important;
  border-radius: 2px;
  border-style: solid;
  font-weight: 900;
}

.tree_selected * {
  font-weight: 500;
}

.tree_view * {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.tree_leaf {
  margin-bottom: 10px;
}


/* -------------------------------------------------------
/* デートピッカー
/* ------------------------------------------------------- */

/* input.react-datepicker-ignore-onclickoutside {
  @apply !w-full shrink-0 leading-[44px] h-[44px] input input-bordered text-black text-[14px] md:text-[15px] font-medium
}

input.react-datepicker-ignore-onclickoutside-remove {
  @apply !w-full outline-none
}
} */
.react-datepicker-wrapper {
  @apply !w-full leading-[44px] h-[44px] rounded-lg shrink-0
}

.react-datepicker__input-container input{
  @apply px-2 leading-[44px] h-[44px] rounded-lg text-black;
}

.react-datepicker__month-text--today {
  @apply !font-normal
}

#container-datepicker .react-datepicker__month-text--selected:not(.react-datepicker__month-text--disabled) {
  @apply !bg-white !text-black
}

#container-datepicker .react-datepicker__month-text--keyboard-selected {
  @apply !bg-white
}

#container-datepicker .react-datepicker__month-text:not(.react-datepicker__month-text--disabled):hover {
  @apply !bg-[#f0f0f0]
}

#container-datepicker .react-datepicker__month-text[aria-selected="true"]:not(.react-datepicker__month-text--disabled) {
  @apply !bg-[#bad9f1]
}

#container-datepicker .react-datepicker__month-text[aria-selected="true"]:not(.react-datepicker__month-text--disabled):hover {
  @apply !bg-[#216ba5] !text-white
}

.bukken_datepicker .react-datepicker__input-container input{
  width: calc(100% - 15px);
}

.bukken_search_pattern .react-datepicker__input-container input{
  @apply px-2 leading-[44px] h-[44px] rounded-lg text-black;
  width: 100%;
}

/*
.bukken_search_pattern .bukken_search_date {
  width: 200px;
}
  */


.bg_trash_near_full {
  background-color: red !important;
}

.pdf_icon{
  color : #ff6e40 !important;
  stroke: #ff6e40 !important;
  fill  : #ff6e40 !important;
}

/* -------------------------------------------------------
/* リスト
/* ------------------------------------------------------- */

.disc_marker_list li{
  @apply list-disc ml-5;
}

.disc_marker_list li::marker {
  color: #1353B7;
  font-size: 18px;
}

input[type="file"]::-webkit-file-upload-button {
  color: white;
  background-color:  #1353B7 !important;
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #FFF;
  border-bottom-color: #0073ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.bigLoader {
  width: 50px;
  height: 50px;
  border: 5px solid #FFF;
  border-bottom-color: #0073ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ------------QUOTATION STATUS COLOR------------ */

/* 一時保存: 001  */
.save_draft_quotation {
  @apply bg-white text-black
}
/* 作成済み: 005 */
.created_quotation {
  @apply bg-white text-black
}
/*送付済み: 010  */
.sent_quotation {
  @apply bg-[#90D26D] text-black
}
/*受注: 015  */
.cancelled_quotation {
  @apply bg-[#008DDA] text-black
}
/*取消: 020  */
.order_received_quotation {
  @apply bg-[#D80032] text-white
}
/*失注: 090  */
.lost_order_quotation {
  @apply bg-[#F7F5F2] text-black
}

.expired_limit_dt_and_status_draft {
  @apply text-[#D80032]
}

/* ------------INVOICE STATUS COLOR------------ */

/* 一時保存: 001 */
.save_draft_invoice {
  @apply bg-white text-black
}
/* 未請求: 005 */
.unclaimed_invoice {
  @apply bg-white text-black
}
/*送付済み: 010  */
.sent_invoice {
  @apply bg-white text-black
}
/*入金済み: 015  */
.partially_deposited_invoice {
  @apply bg-white text-[#CCD3CA]
}
/*一部入金済み: 020  */
.payment_completed_invoice {
  @apply bg-white text-black
}
/*破棄: 090  */
.discarded_invoice {
  @apply bg-white text-[#CCD3CA]
}

/* ポータルアプリメニューアイコンホバー */
.app_menu_btn:hover{
  /* opacity: 0.5; */
  background-color:#d9f8ff ; /* ホバー時の色  テーブルは#F2FDFEだが薄すぎる*/
}