
/**
 * テーブルのヘッダ(ボタン)
 **/
.data-table-header-button {
  @apply text-[18px] overflow-hidden
}

/**
 * テーブルのヘッダ
 **/
.data-table-header {
  @apply text-[18px] resize-x overflow-hidden
}

/**
 * テーブルの行の選択状態
 **/
.data-table-row-selected {
  @apply bg-green-100
}

/**
 * テーブルの行の通常状態
 **/
.data-table-row {
  @apply bg-transparent
}