
.cadewa_primary_label {
  @apply flex;
  height: 44px;
}

.cadewa_primary_label div {
  @apply w-[40px];
  background-image: url("../public/cadewa/SupportLabel.PNG");
  background-size: cover;
}

.cadewa_primary_label span {
  @apply text-[24px] font-bold ml-[5px] self-center;
}